import React, { createContext, useEffect, useState } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { map, find, isFunction } from 'lodash-es'
import {
  MediaSpec,
  BreakpointName,
  BreakpointSpec,
  BreakpointListener,
} from './domain'
import { FC } from '../../types/common'

export interface IDeviceLayout {
  sitecoreContext: {
    deviceLayout: string
  }
}

// by default, the current breakpoint is src
export const CurrentBreakpoint = createContext('source')

export const defaultBreakpoints: BreakpointSpec[] = [
  ['mobile', '(max-width: 767px)'],
  ['tablet', '(min-width: 768px) and (max-width: 1023px)'],
  ['laptop', '(min-width: 1024px) and (max-width: 1199px)'],
  ['desktop', '(min-width: 1200px) and (max-width: 1399px)'],
  ['wide', '(min-width: 1400px)'],
]

export const getDeviceLayout = (context?: { deviceLayout: string }) => {
  return (context && context.deviceLayout) || 'source'
}

export interface IBreakpoints {
  breakpoints?: BreakpointSpec[]
  BreakpointProvider?: React.Provider<BreakpointName>
}

// server gets the default breakpoint value
// browser spins up media listeners that change provided value
export function MediaListenerComponent({
  sitecoreContext,
  children,
  breakpoints = defaultBreakpoints,
  BreakpointProvider = CurrentBreakpoint.Provider,
}: React.PropsWithChildren<IDeviceLayout & IBreakpoints>) {
  const [currentBreakpoint, setBreakpoint] = useState(
    getDeviceLayout(sitecoreContext)
  )

  useEffect(() => {
    if (window.matchMedia) {
      // create tuples of [breakpointName, queryListener]
      const listeners: BreakpointListener[] = map(
        breakpoints,
        ([name, rule] : [string, string]) : [BreakpointName, MediaQueryList] => {
          return [name, window.matchMedia(rule)]
        }
      )

      // find the first breakpoint that's active
      const startPoint = find(
        listeners,
        ([, query]) => query.matches
      ) as BreakpointListener

      // im not really sure why sometimes "startPointName" isn't iterable (causes application crash)
      // but this will at least prevent the error and default to desktop.
      // ill have to look into this another time: frankie 12-19-2024
      if (!startPoint) {
        console.warn('No matching breakpoint found. Falling back to "source".');
        setBreakpoint('desktop');
        return;
      }
      
      const [startPointName] = startPoint

      // add new breakpoint to the state
      console.log('[Media] Initial client breakpoint ', startPointName)
      setBreakpoint(startPointName)

      listeners.forEach(([breakName, query]) => {
        // addListener might be marked deprecated, but it is supported by IE11, and addEventListener is not
        query.addListener(({ matches }) => {
          if (matches) {
            console.log(`[Media] change listener for `, breakName)
            setBreakpoint(breakName)
          }
        })
      })
    }
  }, [])

  return (
    <BreakpointProvider value={currentBreakpoint}>
      {children}
    </BreakpointProvider>
  )
}

export const MediaListener = withSitecoreContext()(
  MediaListenerComponent
) as FC<IBreakpoints>

export type MediaProps = {
  source?: MediaSpec
  mobile?: MediaSpec
  tablet?: MediaSpec
  laptop?: MediaSpec
  desktop?: MediaSpec
  wide?: MediaSpec
}

// Media component accepts functions which will run to return elements at different screen sizes
// It has a default consumer for calculating the current breakpoint, but if want the breakpoint
// to fall in a different place, we can switch the consumer
export const getMedia = (
  BreakpointConsumer: React.Consumer<BreakpointName>
) => {
  return function Media({
    source,
    mobile,
    tablet,
    laptop,
    desktop,
    wide,
  }: MediaProps) {
    return (
      <BreakpointConsumer>
        {(currentBreakpoint: BreakpointName) => {
          switch (currentBreakpoint) {
            case 'source':
              return checkpoints([source, mobile], source)
            case 'mobile':
              return checkpoints([mobile, source], mobile)
            case 'tablet':
              return checkpoints([tablet, mobile, source], tablet)
            case 'laptop':
              return checkpoints([laptop, tablet, mobile, source], laptop)
            case 'desktop':
              return checkpoints(
                [desktop, laptop, tablet, mobile, source],
                desktop
              )
            case 'wide':
              return checkpoints(
                [wide, desktop, laptop, tablet, mobile, source],
                wide
              )
            default:
              return checkpoints([source, mobile], true)
          }
        }}
      </BreakpointConsumer>
    )
  }
}

export default getMedia(CurrentBreakpoint.Consumer)

export function checkpoints(
  breakpoints: (MediaSpec | undefined)[],
  switchOff: MediaSpec | undefined
): JSX.Element | false {
  if (switchOff === false || switchOff === null) {
    return false
  }

  // check all of our breakpoint props in order, returning the first that has a value
  const getComponent = find(breakpoints, isFunction)
  // if we find a matching breakpoint, run it to generate the Element to render
  return getComponent && getComponent()
}
