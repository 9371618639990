import React, { ReactNode, useEffect, useState } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import TrustPilotWidget from '../../components/TrustPilot-Widget/TrustPilotWidget'
import Media from '../../components/Media'

export type HeroProps = {
  heading: JSX.Element
  backgroundImage: JSX.Element
  isDark?: boolean
  quoteButtonText?: string
  className?: string
  children?: ReactNode
  tag?: string
  showTrustPilotWidget?: boolean
}

const Hero = ({
  backgroundImage,
  heading,
  isDark,
  quoteButtonText,
  className,
  children,
  tag = 'h1',
  showTrustPilotWidget,
}: HeroProps) => {
  // if the background image is not dark, meaning background is light, use dark text
  const darkTextClass = !isDark && styles.darkText

  // if tag is 'div', use h1 style (visually h1)
  const tagStyle = tag === 'div' && 'h1'

  return (
    <div
      className={clsx(styles.base, className, !isDark && styles.lightBg)}
      data-test-context="Hero"
    >
      <div className={clsx(showTrustPilotWidget && styles.headingContainer)}>
        <div
          className={clsx(
            styles.title,
            tagStyle,
            showTrustPilotWidget && styles.headingWithTrustPilot,
            darkTextClass
          )}
        >
          {heading}
        </div>
        {showTrustPilotWidget && (
          <div
            className={styles.tpMicroHeroWidget}
            data-test-id={'hero-tp-widget'}
          >
            <Media
              mobile={() => (
                <TrustPilotWidget type={'microstar'} darkBg={isDark} />
              )}
              tablet={() => (
                <TrustPilotWidget type={'microcombo'} darkBg={isDark} />
              )}
            />
          </div>
        )}
      </div>
      <div className={styles.ctaRoot}>{children}</div>
      <div className={styles.img}>{backgroundImage}</div>
    </div>
  )
}

export default Hero
