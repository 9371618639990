import React, { Suspense } from 'react'
import loadable from '@loadable/component'

import { Placeholder, RouteData, Text } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'

import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import Hero, { HeroProps } from '../../page-components/Hero'
import HeroAppStartSwitch from '../../quotes/HeroAppStartSwitch'
import CTABanner, { ICTABannerProps } from '../../components/CTA-Banner'
import PolicyDescription, {
  PolicyDescriptionItem,
} from '../../components/Policy-Description'
import PageDescription from '../../components/Page-Description'
import { ICTABannerEscapeText } from '../../page-components/getEscapeText'
import IconTabAdapter, {
  SitecoreTab,
} from '../../page-components/IconTabs/Adapter'

import { TextField, BooleanField } from '../../types/SitecoreAdapter'
import BreadcrumbList from '../../page-components/Breadcrumb/BreadcrumbList'
import BreadcrumbsFromRoute from '../../page-components/Breadcrumb/BreadcrumbsFromRoute'
import styles from './styles.module.scss'

import UpdatedDate from '../../page-components/UpdatedDate'
import { get } from 'lodash-es'
import Section from '../../components/Section/Section'

const CTAAppStartSwitch = loadable(
  () => import('../../quotes/CTAAppStartSwitch')
)
const TestMetadata = loadable(() => import('../../components/Test-Metadata'))

type PolicyPageProps = {
  route: {
    fields: {
      pageBase: {
        fields: {
          hero: {
            fields: HeroProps
          }
          iconTabs: SitecoreTab[]
          ctaBanner: {
            fields: ICTABannerProps
          } & ICTABannerEscapeText
        }
      }
      heading: TextField
      policy: PolicyDescriptionItem
      pageDescription: TextField
      updatedDate: TextField
      lastPublishedDate: TextField
      tpWidgetGlobalSettings: {
        fields: {
          enableTrustPilotHeroWidget: BooleanField
        }
      }
    }
  } & RouteData
}

const PolicyPage = ({ route }: PolicyPageProps) => {
  const {
    fields: {
      pageBase: {
        fields: { hero, iconTabs, ctaBanner },
      },
      heading,
      policy,
      pageDescription,
      tpWidgetGlobalSettings: {
        fields: { enableTrustPilotHeroWidget },
      },
    },
  } = route

  const description = policy ? (
    <PolicyDescription
      testId={'PolicyPage-PolicyDescription'}
      fields={policy.fields}
    />
  ) : (
    <PageDescription pageDescription={pageDescription} />
  )

  const heroProps = {
    ...hero.fields,
    applyDefaultImgSizing: true,
    enableTrustPilotHeroWidget,
  }

  return (
    <>
      <Nav />
      <Banner />
      <Suspense>
        <div className="policy copy-article">
          <TestMetadata pageType="Policy" appStartType="Typeahead" />

          <Hero {...heroProps} tag={'div'}>
            <HeroAppStartSwitch
              quoteButtonTextOverride={heroProps.quoteButtonText?.value}
              darkBg={get(hero, 'fields.isDark.value', false)}
              hasPolicyRecCTA={get(
                hero,
                'fields.showTrustPilotWidget.value',
                false
              )}
            />
          </Hero>

          <Placeholder name="Hero-Placeholder" rendering={route} />

          <IconTabAdapter tabs={iconTabs} />

          <BreadcrumbsFromRoute>
            {(breadcrumbs) => (
              <BreadcrumbList
                showWrap={false}
                breadcrumbs={breadcrumbs}
                isSticky={false}
                className={clsx('contain', styles.breadcrumb, 'policyCrumb')}
                showCurrentPageCrumb={false}
              />
            )}
          </BreadcrumbsFromRoute>

          {(heading || description) && (
            <Section>
              <Text
                tag="h1"
                field={heading}
                className={clsx('block', styles.heading, 'contain')}
              />

              {description}
            </Section>
          )}

          <Placeholder name="Policy-Placeholder" rendering={route} />

          <UpdatedDate
            date={route.fields.updatedDate}
            fallbackDate={route.fields.lastPublishedDate}
          />

          {ctaBanner && (
            <Section>
              <CTABanner testId="PolicyPage-CTABanner" {...ctaBanner.fields}>
                <CTAAppStartSwitch />
              </CTABanner>
            </Section>
          )}
        </div>
      </Suspense>
    </>
  )
}

export default PolicyPage
