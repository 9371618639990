import React, { ReactNode } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import {
  BooleanField,
  TextField,
  ImageField,
} from '../../types/SitecoreAdapter'
import Hero from './Hero'
import { getResponsiveHeroImage } from '../../components/Responsive-Image/HeroImageAdapter'
import { ResponsiveImageProps } from '../../components/Responsive-Image'

export type HeroProps = {
  heading: TextField
  descriptor: TextField
  backgroundImage: ImageField
  isDark?: BooleanField
  quoteButtonText?: TextField
  className?: string
  children?: ReactNode
  tag?: string
  showTrustPilotWidget?: BooleanField
} & ResponsiveImageProps

type ImageSizingProps = {
  applyDefaultImgSizing?: boolean
}

export type TP_HeroOverrideSettings = {
  enableTrustPilotHeroWidget?: BooleanField
}

const HeroAdapter = ({
  backgroundImage,
  mobileImage,
  tabletImage,
  laptopImage,
  desktopImage,
  heading,
  isDark,
  className,
  children,
  tag = 'h1',
  applyDefaultImgSizing,
  showTrustPilotWidget,
  enableTrustPilotHeroWidget,
}: HeroProps & ImageSizingProps & TP_HeroOverrideSettings) => {
  const responsiveImage = getResponsiveHeroImage({
    mobileImage,
    tabletImage,
    laptopImage,
    desktopImage,
    backgroundImage,
    applyDefaultImgSizing,
  })

  const showTPWidget =
    (enableTrustPilotHeroWidget && enableTrustPilotHeroWidget.value) ||
    (showTrustPilotWidget && showTrustPilotWidget.value)

  return (
    <Hero
      backgroundImage={responsiveImage}
      heading={<Text tag={tag} field={heading} />}
      className={className}
      isDark={isDark && isDark.value}
      tag={tag}
      showTrustPilotWidget={showTPWidget}
    >
      {children}
    </Hero>
  )
}

export default HeroAdapter
