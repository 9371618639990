import React, { Suspense } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { Helmet } from 'react-helmet'

import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import Pagination from '../../components/Pagination'

import NoTag from './NoTag'
import TagResults from './TagResults'
import CTABanner from '../../components/CTA-Banner'
import SearchAppStartContext from '../../quotes/experiences/search-app-start/context'
import SearchAppStart from '../../quotes/experiences/search-app-start/embeds/cta'

import Breadcrumb from '../../page-components/Breadcrumb/BreadcrumbList'
import { getSmallBusinessInsuranceBreadcrumb } from '../../util/getSBIBreadcrumb'

import styles from './styles.module.scss'
import TagResultsContact from '../../components/Contact-Bar/components/TagResultsContact'

// TODO: Should type this and tag results component out
type TagResultsAdapterProps = {
  sitecoreContext: any
}

function TagResultsAdapter({ sitecoreContext }: TagResultsAdapterProps) {
  if (!sitecoreContext.tagResults) {
    return <NoTag />
  }

  const {
    tagResults: {
      tagPath,
      tagName,
      cards,
      currentPage,
      totalResults,
      pageSize,
      canonicalUrl,
      urlBase,
      nextPath,
      prevPath,
      title,
      metaDescription,
      isBlog,
      relatedTags,
    },
    route,
  } = sitecoreContext

  // Next and previous urls are divided into parts, urlBase and an absolute path.
  // The client-side routing, using React Router, can use the absolute path.
  // The <link rel="next" ..> and <link rel="prev" ..> tags use urls that include hostname.

  const handleNext = (event: MouseEvent) => {
    window.location.assign(urlBase + nextPath);


  }

  const handlePrevious = (event: MouseEvent) => {
    window.location.assign(urlBase + prevPath);
  }

  const nextMeta = nextPath && <link rel="next" href={urlBase + nextPath} />
  const prevMeta = prevPath && <link rel="prev" href={urlBase + prevPath} />

  const paging = (
    <Pagination
      testId={'TagResults-Pagination'}
      currentPage={currentPage}
      totalResults={totalResults}
      pageSize={pageSize}
      nextPath={nextPath}
      prevPath={prevPath}
      onNextClick={handleNext}
      onPreviousClick={handlePrevious}
    />
  )

  const ctaBanner = route?.fields?.bottomCta && (
    <CTABanner
      testId="TagResultsPage-CTABanner"
      {...route.fields.bottomCta.fields}
    >
      <SearchAppStartContext>
        <SearchAppStart
          appStartKey="TagResultsCTABanner"
          rootClass={styles.ctaRoot}
        />
      </SearchAppStartContext>
    </CTABanner>
  )

  const breadcrumbs = getSmallBusinessInsuranceBreadcrumb(tagName)

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta name="description" content={metaDescription} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content={canonicalUrl} />

        {nextMeta}
        {prevMeta}
      </Helmet>

      <Nav />
      <Banner />
      <Suspense>
        <Breadcrumb
          breadcrumbs={breadcrumbs}
          showCurrentPageCrumb={false}
          className={styles.breadcrumb}
        />
        <TagResults
          tagName={tagName}
          tagPath={tagPath}
          cards={cards}
          contact={<TagResultsContact />}
          pagination={paging}
          isBlog={isBlog}
          relatedTags={relatedTags}
          ctaBanner={ctaBanner}
        />
      </Suspense>
    </>
  )
}

export default withSitecoreContext()(TagResultsAdapter)
