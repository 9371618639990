import React from 'react'
import Helmet from 'react-helmet'
import { RouteFields, TextField } from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

type JsonLdProps = RouteFields<{
  jsonLdGenerated: TextField,
  jsonLdManual: TextField,
}>

const BuildElement = (s: string) : JSX.Element => <script type="application/ld+json">{decodeURIComponent(s)}</script>

const GetElements = (field : TextField): JSX.Element[] => {
  let parsed = JSON.parse(field.value || '[]');
  let elements : JSX.Element[];
  if (Array.isArray(parsed)) {
    elements = parsed.map(e => BuildElement(JSON.stringify(e)));
  } else {
    elements = [ BuildElement(field.value) ];
  }
  return elements;
}

// Machine Readable Linked Data for the website.
// Loaded as a script with the layout
const JsonLd = ({
  sitecoreContext: {
    route: {
      fields: { jsonLdGenerated, jsonLdManual },
    },
  },
}: JsonLdProps) => {

  let generatedElements = GetElements(jsonLdGenerated);
  let manualElements = GetElements(jsonLdManual);

  return (
    <Helmet>
      { generatedElements }
      { manualElements }
    </Helmet>
  )
}

export default withSitecoreContext()(JsonLd)
