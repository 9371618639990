import React from 'react';
import clsx from 'clsx';
import { RouterItemProps } from '../../types';
import styles from './styles.module.scss';

export default function RouterItem({
  image,
  text,
  href,
  title,
  styleClassName = 'carousel',
  className = '',
}: RouterItemProps) {
  return (
    <div
      className={clsx(
        styles[styleClassName],
        styleClassName === 'grid' ? styles.item : ''
      )}
    >
      <a
        href={href}
        title={title}
        className={clsx(className, styles.anchor)}
      >
        {image && <div className={styles.image}>{image}</div>}
        <span className={styles.text}>{text}</span>
      </a>
    </div>
  );
}
